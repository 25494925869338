import { AssetsProvider, getHomeUrl, useRouter } from '@obvio/app'
import { getStaticPropsPages, getStaticPathsPages } from '@obvio/server'
import { values } from '@obvio/utils'
import { NextSeo } from 'next-seo'
import { useEffect } from 'react'

import { globalStore } from './_app'
import { schema } from '@/api/schema'
import * as FEATURES from '@/api/schema/features'
import { Cursor, useCursorContext } from '@/components/Cursor'
import { Layout } from '@/components/Layout'
import { MeetingModal } from '@/components/MeetingModal'
import { Renderer } from '@/components/Renderer'
import { RESOURCE_PREFIXES } from '@/constants'
import { QUERIES } from '@/constants/queries'
import { LayoutContentProvider } from '@/contexts/LayoutContentContext'

import type { LayoutContextName } from '@/contexts/LayoutContentContext'
import type { RendererData } from '@obvio/layout/renderer'
import type { GetStaticPropsContext } from 'next'
import type { ReactElement } from 'react'

function Page({
  page,
}: {
  page: {
    id: string
    slug: string
    data: RendererData<any, any>[]
    layoutRef: { title: string }
    seoTitle?: string
    seoDescription?: string
  }
}): ReactElement {
  const { setParam } = useRouter()
  const { setNavTransparent } = useCursorContext()
  const [{ meeting }, dispatch] = globalStore('meeting')
  const canonical = `${getHomeUrl()}/${page.slug ?? ''}`

  const router = useRouter()
  const { setValue } = useCursorContext()

  useEffect(() => {
    const handleRouteChange = () => setValue(null)

    router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events, setValue])

  useEffect(() => {
    if (meeting) {
      void setParam('meeting', 'true')
      dispatch('MEETING', false)
    }
  }, [dispatch, meeting, setParam])

  useEffect(() => {
    setNavTransparent(false)
  }, [setNavTransparent])

  return (
    <LayoutContentProvider
      key={page.layoutRef.title}
      value={page.layoutRef.title as LayoutContextName}
    >
      <NextSeo
        canonical={canonical}
        {...(page.seoTitle && { title: page.seoTitle })}
        {...(page.seoDescription && { description: page.seoDescription })}
      />
      <Cursor key={page.id} kind="black" />
      <AssetsProvider path={`/page/${page.id}`}>
        <Layout key={page.id} context="page">
          <Renderer data={page.data} pageId={page.id} />
        </Layout>
        <MeetingModal />
      </AssetsProvider>
    </LayoutContentProvider>
  )
}

export async function getStaticProps(
  ctx: GetStaticPropsContext<{ slug: string[] }>,
): Promise<ReturnType<typeof getStaticPropsPages>> {
  return getStaticPropsPages(ctx, schema, {
    queries: QUERIES,
    paths: RESOURCE_PREFIXES,
    features: values(FEATURES).map((v) => v.id),
  })
}

export async function getStaticPaths(): Promise<
  ReturnType<typeof getStaticPathsPages>
> {
  return getStaticPathsPages(schema)
}

export default Page
