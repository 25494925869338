import { mobile, styled, Text } from '@obvio/app'
import { Stack } from '@obvio/ui'

export const ModalTitle = styled(Text)`
  font-size: 2.25rem;
  line-height: 110%;
  margin-bottom: 0;
`

export const ModalInputs = styled(Stack)`
  > div > div {
    display: grid;
    grid-template-columns: minmax(12rem, auto) 1fr;

    > label {
      text-align: left;
      justify-content: flex-start;
      position: static;
      transform: none;
      &[aria-invalid] {
        border-color: ${(theme) => theme.colors.error};
      }
    }

    @media ${mobile} {
      grid-template-columns: 1fr;
      > label {
        position: absolute;
        left: 0;
        top: 0;
        border-bottom: 0;
        &.focus-visible,
        &.with-value {
          font-size: 0.75rem;
          top: -0.75rem;
        }
      }
    }
  }
`
