import { createContext, useContext } from 'react'

export type LayoutContextName = 'Main' | 'Store'

const Context = createContext<LayoutContextName>('Main')

export const LayoutContentProvider = Context.Provider

export const useLayoutContentType = (): LayoutContextName => {
  return useContext(Context)
}
