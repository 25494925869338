import {
  Context,
  css,
  Link,
  notDesktop,
  parseResourceLink,
  styled,
  Text,
  useContextPath,
} from '@obvio/app'
import { EventCarousel, RichText } from '@obvio/template'
import { Stack } from '@obvio/ui'
import dynamic from 'next/dynamic'
import { useMemo } from 'react'

import { CenteredSection } from '../CenteredSection'
import { useCursorContext } from '../Cursor'
import { ReadMoreLink } from '../ReadMoreLink'
import { useLayoutContentType } from '@/contexts/LayoutContentContext'

import type { ImageAsset } from '@obvio/app'
import type { ReactElement } from 'react'

const ArtistsListMainLazy = dynamic(async () => {
  const { ArtistsListMain } = await import(
    /* webpackChunkName: "ArtistsListMain" */
    '../ArtistsListMain'
  )
  return ArtistsListMain
})

const ArtistsListLazy = dynamic(async () => {
  const { ArtistsList } = await import(
    /* webpackChunkName: "ArtistsList" */
    '../ArtistsList'
  )
  return ArtistsList
})

const BoardMembersLazy = dynamic(async () => {
  const { BoardMembers } = await import(
    /* webpackChunkName: "BoardMembers" */
    '../About/BoardMembers'
  )
  return BoardMembers
})

const ArtistFeaturedPhotoLazy = dynamic(async () => {
  const { ArtistFeaturedPhoto } = await import(
    /* webpackChunkName: "ArtistFeaturedPhoto" */
    '../Artist/ArtistFeaturedPhoto'
  )
  return ArtistFeaturedPhoto
})

const ArtistOfTheDayLazy = dynamic(async () => {
  const { ArtistOfTheDay } = await import(
    /* webpackChunkName: "ArtistOfTheDay" */
    '../ArtistOfTheDay'
  )
  return ArtistOfTheDay
})

const ArtGridLazy = dynamic(async () => {
  const { ArtGrid } = await import(
    /* webpackChunkName: "Images" */
    '../ArtGrid'
  )
  return ArtGrid
})

const FormCuratorLazy = dynamic(async () => {
  const { FormCurator } = await import(
    /* webpackChunkName: "FormCurator" */
    '../Forms/FormCurator'
  )
  return FormCurator
})

const FormPartnerLazy = dynamic(async () => {
  const { FormPartner } = await import(
    /* webpackChunkName: "FormPartner" */
    '../Forms/FormPartner'
  )
  return FormPartner
})

const FormApplyLazy = dynamic(async () => {
  const { FormApply } = await import(
    /* webpackChunkName: "FormApply" */
    '../Forms/FormApply'
  )
  return FormApply
})

const FormSellOrBuyLazy = dynamic(async () => {
  const { FormSellOrBuy } = await import(
    /* webpackChunkName: "FormSellOrBuy" */
    '../Forms/FormSellOrBuy'
  )
  return FormSellOrBuy
})

const FormAdvisorLazy = dynamic(async () => {
  const { FormAdvisor } = await import(
    /* webpackChunkName: "FormAdvisor" */
    '../Forms/FormAdvisor'
  )
  return FormAdvisor
})

const FormJoinLazy = dynamic(async () => {
  const { FormJoin } = await import(
    /* webpackChunkName: "FormJoin" */
    '../Forms/FormJoin'
  )
  return FormJoin
})

const FormFutureEventLazy = dynamic(async () => {
  const { FormFutureEvent } = await import(
    /* webpackChunkName: "FormFutureEvent" */
    '../Forms/FormFutureEvent'
  )
  return FormFutureEvent
})

const FormContactLazy = dynamic(async () => {
  const { FormContact } = await import(
    /* webpackChunkName: "FormContact" */
    '../Forms/FormContact'
  )
  return FormContact
})

const MapLazy = dynamic(async () => {
  const { Map } = await import(
    /* webpackChunkName: "Map" */
    '../Map'
  )
  return Map
})

const BlogGridLazy = dynamic(async () => {
  const { BlogGrid } = await import(
    /* webpackChunkName: "BlogGrid" */
    '@obvio/template'
  )
  return BlogGrid
})

const BlogCarouselLazy = dynamic(async () => {
  const { BlogCarousel } = await import(
    /* webpackChunkName: "BlogCarousel" */
    '@obvio/template'
  )
  return BlogCarousel
})

const CtaBannerLazy = dynamic(async () => {
  const { CtaBanner } = await import(
    /* webpackChunkName: "CtaBanner" */
    '../CtaBanner'
  )
  return CtaBanner
})

const AccordionUrlLazy = dynamic(async () => {
  const { AccordionUrl } = await import(
    /* webpackChunkName: "AccordionUrl" */
    '../AccordionUrl'
  )
  return AccordionUrl
})

const AccordionDescriptionLazy = dynamic(async () => {
  const { AccordionDescription } = await import(
    /* webpackChunkName: "AccordionDescription" */
    '../AccordionDescription'
  )
  return AccordionDescription
})

const AccordionUrlDescriptionLazy = dynamic(async () => {
  const { AccordionUrlDescription } = await import(
    /* webpackChunkName: "AccordionUrlDescription" */
    '../AccordionUrlDescription'
  )
  return AccordionUrlDescription
})

const InteractiveTabsLazy = dynamic(async () => {
  const { InteractiveTabs } = await import(
    /* webpackChunkName: "InteractiveTabs" */
    '@obvio/template'
  )
  return InteractiveTabs
})

const ArtGridSection = styled(CenteredSection)`
  padding: 0 !important;
  width: 100vw;
  max-width: 100vw !important;
  @media ${notDesktop} {
    overflow-x: hidden;
  }
`

const outerCss = css`
  min-height: calc(100vh + var(--nav-height));
  width: 100vw;
  padding: 0 !important;
  max-width: 100vw !important;
`

const StyledEventCarousel = styled(EventCarousel)`
  > h2 {
    max-width: calc(100% - 7.5rem);
    width: 84.25rem;
    margin: 0 auto;
  }
  > div {
    heigth: 30rem;
    position: relative;
  }
  padding: 7.5rem 0;
`

export const customRenderers: (
  pageId?: string,
) => Record<string, (props: any) => ReactElement | null> = (_pageId) => ({
  'accordion-url-description': ({ accordion, text }) => (
    <CenteredSection>
      <AccordionUrlDescriptionLazy accordion={accordion} text={text} />
    </CenteredSection>
  ),
  'events-carousel': () => <StyledEventCarousel />,
  'art-grid': ({ Art: art }) => (
    <ArtGridSection outerCss={outerCss}>
      <ArtGridLazy art={art} />
    </ArtGridSection>
  ),
  image: ({ img }: { img: ImageAsset[] }) => (
    <ArtistFeaturedPhotoLazy image={img[0]} />
  ),
  'artist-of-the-day': ({ artist }) => (
    <ArtistOfTheDayLazy id={parseResourceLink(artist[0].id)?.id} />
  ),
  'artist-index': function Comp() {
    const layoutType = useLayoutContentType()
    return (
      <CenteredSection>
        {layoutType === 'Main' ? <ArtistsListMainLazy /> : <ArtistsListLazy />}
      </CenteredSection>
    )
  },
  'tabs-img-richText-cta': function Comp({ accordion }) {
    const { setKind } = useCursorContext()
    const tabs = useMemo(
      () =>
        accordion.map((v: any) => ({
          ...v,
          data: { ...v.data, image: v.data.img1 },
        })),
      [accordion],
    )
    return (
      <div
        role="presentation"
        onMouseOver={() => setKind('white')}
        onFocus={() => setKind('white')}
        onMouseOut={() => setKind('black')}
        onBlur={() => setKind('black')}
      >
        <Context name="menuPanel">
          <InteractiveTabsLazy tabs={tabs} pageId={_pageId} />
        </Context>
      </div>
    )
  },
  'board-grid': () => (
    <CenteredSection>
      <BoardMembersLazy />
    </CenteredSection>
  ),
  'accordion-url': ({ accordion, text }) => (
    <CenteredSection>
      <AccordionUrlLazy accordion={accordion} text={text} />
    </CenteredSection>
  ),
  'accordion-description': ({ accordion, text }) => (
    <CenteredSection>
      <AccordionDescriptionLazy accordion={accordion} text={text} />
    </CenteredSection>
  ),
  'form-partner': () => (
    <CenteredSection>
      <FormPartnerLazy />
    </CenteredSection>
  ),
  'form-apply': () => (
    <CenteredSection>
      <FormApplyLazy />
    </CenteredSection>
  ),
  'form-curator': () => (
    <CenteredSection>
      <FormCuratorLazy />
    </CenteredSection>
  ),
  'form-advisor': () => (
    <CenteredSection>
      <FormAdvisorLazy />
    </CenteredSection>
  ),
  'form-sell-buy': () => (
    <CenteredSection>
      <FormSellOrBuyLazy />
    </CenteredSection>
  ),
  'form-contact': () => (
    <CenteredSection>
      <FormContactLazy />
    </CenteredSection>
  ),
  'form-future-event': () => (
    <CenteredSection>
      <FormFutureEventLazy />
    </CenteredSection>
  ),
  'form-join': () => (
    <CenteredSection>
      <FormJoinLazy />
    </CenteredSection>
  ),
  'title-richText-cta': function Comp(props) {
    const { setValue } = useCursorContext()
    return (
      <Context name="heading">
        <CenteredSection>
          <Stack kind="vertical">
            {props.title ? (
              <Text tag="h1" as="h1">
                {props.title}
              </Text>
            ) : null}
            {props.url ? (
              <div
                role="presentation"
                onFocus={() => setValue(props.ctaText ?? 'GO')}
                onMouseOver={() => setValue(props.ctaText ?? 'GO')}
                onMouseOut={() => setValue(null)}
                onBlur={() => setValue(null)}
              >
                <Link href={props.url}>
                  <RichText value={props.richText} asMap={{ h3: 'h4' }} />
                </Link>
              </div>
            ) : (
              <div>
                <RichText value={props.richText} asMap={{ h3: 'h4' }} />
              </div>
            )}
          </Stack>
        </CenteredSection>
      </Context>
    )
  },
  map: () => <MapLazy />,
  'blog-grid': () => (
    <Context name="withGrid">
      <CenteredSection>
        <BlogGridLazy />
      </CenteredSection>
    </Context>
  ),
  'blog-carousel': function Comp() {
    const articlePath = useContextPath('article')
    const { setValue } = useCursorContext()
    return (
      <Context>
        <div
          role="presentation"
          onMouseOver={() => setValue('DRAG')}
          onFocus={() => setValue('DRAG')}
          onMouseOut={() => setValue(null)}
          onBlur={() => setValue(null)}
        >
          <BlogCarouselLazy
            allText={
              <ReadMoreLink color="black" href={articlePath} length="3rem">
                ALL FROM BLOG
              </ReadMoreLink>
            }
          />
        </div>
      </Context>
    )
  },
  'richText-heading': ({ richText }) => (
    <CenteredSection>
      <RichText asMap={{ h2: 'sub-h1' }} value={richText} />
    </CenteredSection>
  ),
  'cta-banner': (data) => (
    <Context name="menuPanel">
      <CtaBannerLazy {...data} pageId={_pageId} />
    </Context>
  ),
})
