import {
  Text,
  Context,
  styled,
  useParamModal,
  useTranslation,
  useMutation,
} from '@obvio/app'
import {
  CheckboxInput,
  Grid,
  GridItem,
  Modal,
  Stack,
  Submit,
  SubmitState,
  TextInput,
} from '@obvio/ui'
import { useCallback } from 'react'

import { FadeIn } from './FadeIn'
import { ModalInputs } from './Modal'

import type { ReactElement } from 'react'

const ButtonGridItem = styled(GridItem)`
  display: flex;
  justify-content: flex-end;
  > button {
    margin: 0 !important;
  }
`

const CTA = styled(Submit)`
  width: fit-content;
  margin: 0 auto !important;
`

const MODAL_PARAM = 'meeting'

export function MeetingModal(): ReactElement {
  const { t } = useTranslation()
  const { hide } = useParamModal(MODAL_PARAM)
  const [sendMeetingForm] = useMutation('meetingForm')

  const handleSubmit = useCallback(
    async (data: {
      name: string
      email: string
      phone: string
      topic: string
    }) => {
      await sendMeetingForm(data)
      await hide()
      return SubmitState.OK
    },
    [hide, sendMeetingForm],
  )

  return (
    <Context name="event">
      <Modal
        title={
          <Text tag="span" as="sub-h1">
            {t('form.meeting.title')}
          </Text>
        }
        onSubmit={handleSubmit}
        parameter={MODAL_PARAM}
        onClose={hide}
      >
        <FadeIn>
          <Stack kind="vertical" spacing="medium">
            <Text>{t('form.meeting.description')}</Text>
            <ModalInputs kind="vertical" spacing="medium">
              <TextInput name="name" label={t('form.label.from')} />
              <TextInput name="email" label={t('form.label.email')} />
              <TextInput name="phone" label={t('form.label.number')} />
              <TextInput name="topic" label={t('form.label.topic')} />
            </ModalInputs>
            <Grid templateColumns="1fr 1fr" gap="medium">
              <GridItem>
                <CheckboxInput
                  name="rodo"
                  label={t('form.rodo')}
                  required
                  noSubmit
                />
              </GridItem>
              <ButtonGridItem>
                <CTA>SEND</CTA>
              </ButtonGridItem>
            </Grid>
          </Stack>
        </FadeIn>
      </Modal>
    </Context>
  )
}
