import { styled } from '@obvio/app'
import { Section } from '@obvio/ui'
import { motion } from 'framer-motion'

import type { SectionProps } from '@obvio/ui'
import type { AllowUndefinedForOptional } from '@obvio/utils'
import type { ReactElement } from 'react'

const StyledCenteredSection = styled(Section)`
  ${(theme) => theme.flexCenter};
  flex-direction: column;
  text-align: center;
`

// @ts-expect-error -- TODO: why this throw an error only in this project??
const MotionSection = motion(StyledCenteredSection)

type CenteredSectionProps = AllowUndefinedForOptional<{
  children: ReactElement | ReactElement[]
  className?: string
  delay?: number
}> &
  Omit<SectionProps, 'first' | 'last' | 'only'>

export function CenteredSection({
  children,
  className,
  delay = 0.25,
  ...sectionProps
}: CenteredSectionProps): ReactElement {
  return (
    <MotionSection
      {...sectionProps}
      initial={{ opacity: 0, y: 25 }}
      whileInView={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 25 }}
      viewport={{ once: true }}
      transition={{ delay, duration: 1 }}
      className={className}
    >
      {children}
    </MotionSection>
  )
}
